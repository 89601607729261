import * as React from 'react';
import { HeadFC } from 'gatsby';
import { SEO } from '../components/Seo';
import { PageLayout } from '../components/PageLayout';
import { graphql } from 'gatsby';
import { CONTAINER_SIZE, palette, routes } from '../core/constants';
import {
  Container,
  List,
  Link,
  ListItem,
  Icon,
  Box,
  Heading,
  Divider,
  Text,
} from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import { AiOutlineCaretRight } from 'react-icons/ai';
import { styles } from '../page-styles/faq.styles';
import './index.css';

type Question = {
  type: string;
  category: string;
  title: string;
  slug: string;
  order: number;
};

type ContentsTableProps = {
  category: string;
  data: Question[];
};

const ContentsTable: React.FC<ContentsTableProps> = ({ category, data }) => {
  const { pathname } = useLocation();

  return (
    <Box>
      <Heading size="md" mt={6} mb={2}>
        {category}
      </Heading>
      <List>
        {data.map((question) => {
          const isActive = pathname.includes(question.slug);
          return (
            <ListItem key={question.slug} mb={1}>
              <Link
                display={'inline-flex'}
                alignItems={'center'}
                fontWeight={isActive ? 'bold' : 'normal'}
                color="brand.500"
                _hover={{
                  color: 'brand.600',
                }}
                as={GatsbyLink}
                to={routes.faq(question.slug)}
              >
                {isActive && <Icon mr={2} as={AiOutlineCaretRight} />}{' '}
                {question.title}
              </Link>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

type FaqPageProps = {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: Omit<Question, 'order'>;
    };
    allMarkdownRemark: {
      nodes: {
        frontmatter: Question;
      }[];
    };
  };
};

const FAQ: React.FC<FaqPageProps> = ({ data }) => {
  const contentsTableData = data.allMarkdownRemark.nodes
    .map((node) => node.frontmatter)
    .filter((node) => node.type === 'faq')
    .sort((a, b) => (a.order > b.order ? 1 : -1)); // sort by order ascending

  const questionsByCategory = contentsTableData.reduce((acc, question) => {
    const questions = acc[question.category] ?? [];
    const newQuestions = [...questions, question];
    acc[question.category] = newQuestions.sort((a, b) =>
      a.order > b.order ? 1 : -1,
    ); // sort by order ascending;
    return acc;
  }, {} as Record<string, Question[]>);

  return (
    <>
      <Heading mt={8} mb={4} size="lg">
        Frequent questions
      </Heading>
      {Object.entries(questionsByCategory).map(([category, data], index) => (
        <ContentsTable
          key={`${category}-${index}`}
          category={category}
          data={data}
        />
      ))}
    </>
  );
};

const isRootFaqPath = (pathname: string) => /faq\/*$/.test(pathname);

const FaqPage: React.FC<FaqPageProps> = ({ data }) => {
  const { html } = data.markdownRemark;
  const { title, category } = data.markdownRemark.frontmatter;

  const { pathname } = useLocation();
  const isRoot = isRootFaqPath(pathname);

  return (
    <PageLayout>
      <Container maxW={CONTAINER_SIZE} py={10}>
        {isRoot && (
          <Heading as="h1" size="lg" mt={0} mb={1}>
            Welcome to our Help Center
          </Heading>
        )}
        {!isRoot && (
          <Box
            bgColor={palette.themeLightGray}
            rounded={'lg'}
            boxShadow={'lg'}
            p={6}
          >
            <Heading as="h1" size="lg" mt={0} mb={1}>
              {title}
            </Heading>
            <Text
              fontSize="sm"
              color="gray.500"
              fontWeight={'bold'}
              textTransform={'uppercase'}
              mb={4}
            >
              Category: {category}
            </Text>
            <div
              className={styles.faqPage}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Box>
        )}
        <Divider mt={12} mb={4} />
        <FAQ data={data} />
      </Container>
    </PageLayout>
  );
};

export default FaqPage;

export const Head: HeadFC<FaqPageProps['data']> = ({ data }) => {
  const { title, slug } = data.markdownRemark.frontmatter;
  const { pathname } = useLocation();
  const isRoot = isRootFaqPath(pathname);

  if (isRoot) {
    return (
      <SEO
        title={`Help Center - instatab`}
        ogTitle={`instatab - Help Center`}
        ogUrl={`https://instatab.io/faq`}
      />
    );
  }

  return (
    <SEO
      title={`${title} - Help Center - instatab`}
      ogTitle={`instatab - Help Center - ${title}`}
      ogUrl={`https://instatab.io/faq/${slug}`}
    />
  );
};

export const query = graphql`
  query FaqPage($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        category
        title
        slug
      }
    }
    allMarkdownRemark {
      nodes {
        frontmatter {
          category
          title
          order
          slug
          type
        }
      }
    }
  }
`;
