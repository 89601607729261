import { css } from '@emotion/css';
import { palette } from '../core/constants';

export const styles = {
  faqPage: css`
    h1 {
      font-size: 2em;
      margin-top: 1.5em;
      margin-bottom: 0.5em;
    }

    h2 {
      font-size: 1.5em;
      margin-top: 1.5em;
      margin-bottom: 0.5em;
    }

    p {
      margin-bottom: 0.5em;
    }

    a {
      color: ${palette.themePrimary};
    }

    ul {
      padding-left: 2em;
      margin-bottom: 0.5em;

      > li {
        margin-bottom: 0.25em;
      }
    }
  `,
};
